import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/hero';
import Nav from '../components/nav';
import AlbumCover from '../components/album-cover';
import styled from 'styled-components';
import { PlayCircle, List } from 'react-feather';
import PuxxelOstArtwork from '../images/releases/puxxel-ost.png';
import JanusOstArtwork from '../images/releases/janus-ost.png';


const IndexPage = () => (
  <Layout fabricBg>
    <SEO title="Home" />
    <Hero>
      <Nav />
    </Hero>

    <SectionContainer style={{ marginTop: 'calc(3rem + 5vw)' }}>
      <Section>
        <SectionTitle id="selected-works"><PlayCircle style={{marginRight: '1rem'}}/> selected works</SectionTitle>
        <Soundcloud />
      </Section>
    </SectionContainer>
    <SectionContainer style={{ marginTop: '2rem' }}>
      <Section>
        <SectionTitle id="releases"><List style={{marginRight: '1rem'}}/> releases (2)</SectionTitle>
        <AlbumList>
          <Album style={{ marginRight: '4rem' }} url={JanusOstArtwork} title="Janus OST" href="https://thisisook.bandcamp.com/album/janus-ost" />
          <Album style={{ marginRight: '4rem' }} url={PuxxelOstArtwork} title="Puxxel OST" href="https://thisisook.bandcamp.com/album/puxxel-ost" />
        </AlbumList>
      </Section>
    </SectionContainer>
  </Layout>
);

const SectionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Section = styled.section`
  width: 100%;
  max-width: 70rem;
  position: relative;
  padding: 0 5rem;

  @media only screen and (max-width: 600px) {
    padding: 0 2rem;
  }
`;

const SectionTitle = styled.h1`
  font-family: Sintony, Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 1.75rem;
  line-height: 2rem;
  letter-spacing: 0.035em;
  color: #FFFFFF;
`;

const AlbumTitle = styled.div`
  color: white;
  display: inline-block;
  text-decoration: none;
  text-transform: lowercase;
  font-family: Sintony, Arial, Helvetica, sans-serif;
  font-size: 0.875rem;
  font-weight: normal;
`;

const RotatedAlbum = styled(AlbumCover)`
  transform: rotate(-7deg);
  margin-bottom: 1rem;
  transition: all 150ms;
  cursor: pointer;
  transform-origin: center center;

  &:hover {
    transform: scale(1.15) translateY(-0.5rem);
    box-shadow: 0 0.5rem 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
  }
`;

const AlbumTitleContainer = styled.a`
  display: block;
  text-decoration: none;
  color: white;
  max-width: 7rem;
  display: flex;
  flex-direction: column;
  align-content: center;
`;

const Album = ({ url, title, href, ...props }) => {
  return (
    <AlbumTitleContainer href={href} target="_blank" {...props}>
      <RotatedAlbum imgUrl={url} size={8.6} />
      <AlbumTitle>{title}</AlbumTitle>
    </AlbumTitleContainer>
  );
}

const AlbumList = styled.div`
  padding-top: 1rem;
  display: flex;
`;

const Soundcloud = () => (
  <iframe
    id="soundcloud-embed"
    allow="autoplay"
    width="100%"
    height="400"
    scrolling="no"
    frameborder="no" 
    src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1027718986&color=%234b46a7&auto_play=false&hide_related=true&show_playcount=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=true"></iframe>
);

export default IndexPage
